import React from "react";

const EmbedPowerBI: React.FC = () => (
		<iframe 
			id="power-bi-iframe" 
			title="Delivery Summaries to Publish" 
			style={{ height: '92vh' }} 
			width="100%" 
			frameBorder="0"
			src="https://app.powerbi.com/reportEmbed?reportId=6202a59f-833a-4351-a67d-36b4b0f6c5c9&amp;autoAuth=true&amp;ctid=9cb36146-2a3f-4c85-8fcf-7715438f7f7e" 
		/>
)

export default EmbedPowerBI;