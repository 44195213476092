// This is for a new section in the left menu, mainly for external links 
// import groups from '../app/schemas/PermissionGroups';

// export default  [
// 	{ 
// 		title: 'Department', 
// 		icon: 'pie-chart',
// 		external: true,
// 		urlPath: '/',
// 		authorize: [groups.ADMIN],
// 		nested: [
// 			{
// 				title: 'Delivery Summaries', 
// 				icon: 'truck',
// 				external: true,
// 				urlPath: 'https://micway.au/bi-delivery-summaries',
// 			}, 
// 		]
// 	},
// ]

import groups from '../app/schemas/PermissionGroups';
import EmbedPowerBI from '../components/features/powerBI/EmbedPowerBI';

export default { 
	path: 'power-bi',
	menuItem: { title: 'Power BI', icon: 'pie-chart' },
	authorize: [groups.ADMIN],
	childRoutes: [
		{ 
			path: 'deliverySummaries', 
			menuItem: { title: 'Delivery Summaries', icon: 'truck' },
			component: EmbedPowerBI
		}
	]
};