/* eslint-disable no-undef */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polygon } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { Row, Col, FormGroup, FormControl, Modal, Button, ControlLabel } from 'react-bootstrap';

import GeofenceApi from '../../../../app/api/GeofenceApi';
import { Loader, Form, ErrorBox } from '../../../common/uiElements';
import { PlaceCategoriesList, PlaceSubCategoriesList } from '../../../common/lists';

import { GoogleMapContainer } from '../../../common/googleMaps';
import AccessPointMarker from './AccessPoint/AccessPointMarker';
import AccessPointsList from './AccessPoint/AccessPointsList';
import AccessPointModal from './AccessPoint/AccessPointModal';

import { placeMarkerOptions } from './options';
import { Checkbox, YesNoRadio } from '../../../common/inputs';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import confirm from '../../../../app/helpers/confirm';

const propTypes = {
	placeData: PropTypes.object,
	geofencePolygonArray: PropTypes.array,
	geofencePolygonBounds: PropTypes.object,
	show: PropTypes.bool,
	onSave: PropTypes.func,
	onClose: PropTypes.func
};

class PlaceModal extends Component {
	constructor(props) {
		super(props);
		
		this.polygonCenter = this.props.geofencePolygonBounds && this.props.geofencePolygonBounds.getCenter();
		this.geocoder = new window.google.maps.Geocoder();

		this.defaultData = {
			name: '',
			placeCategoryId: '',
			placeSubCategoryId: '',
			storeNumber: '',
			email: '',
			landlineNumber: '',
			mobileNumber: '',
			fullAddress: '',
			keySerialNumber: '',
			doorCode: '',
			alarmCode: '',
			isPreStartEnabled: false,
			isSafePlaceEnabled: false,
			isPalletJackLiveEnabled: false,
			isStartShiftEnabled: false,
			isTailgateRequired: '',
			lat: null,
			lng: null,
			isActive: true,
			AccessPoints: []
		};

		this.state = {
			selectedAccessPointIndex: null,
			placeData: {
				...this.defaultData
			},
			isLoadingPlace: false,
			errorLoadingPlace: null,
			isAddingAccessPoint: false,
			isLoadingMap: true,
		};

		this.validations = {
			name: 'required',
			placeCategoryId: 'required',
			placeSubCategoryId: 'required',
			fullAddress: 'required',
			storeNumber: 'required',
			isTailgateRequired: 'required',
			email: 'email'
		};
	}

	componentDidMount() {
		const { placeData } = this.props;
		if (!placeData)
			return this.addNewPlaceMarker();

		const { id, hasBeenEdited } = placeData;
		if (id && !hasBeenEdited)
			return this.fetchPlaceData();

		this.setState({
			placeData
		}, this.setMapsToFitBounds);
	}

	fetchPlaceData = () => {
		const promise = () => GeofenceApi.getPlace(this.props.placeData.id);
		componentRequestHandler(this, promise, 'placeData', {
			defaultValue: { ...this.defaultData },
			errorAttrName: 'errorLoadingPlace',
			loadingAttrName: 'isLoadingPlace'
		})
		.then(this.setMapsToFitBounds);
	}

	fitMapsOnGeofence = () => {
		const { geofencePolygonBounds } = this.props;
		if (!geofencePolygonBounds)
			return;
			
		this.placesMap.fitBounds(geofencePolygonBounds, 1);
		this.accessPointsMap.fitBounds(geofencePolygonBounds, 1);

		if (!this.state.placeData.fullAddress)
			this.handlePlacePositionChanged();
	}

	setMapsToFitBounds = () => {
		const interval = window.setInterval(() => {
			if (!this.state.isLoadingPlace && this.accessPointsMap && this.placesMap) {
				window.clearInterval(interval);
				this.setState({ 
					isLoadingMap: false 
				}, this.fitMapsOnGeofence);
			}
		}, 500);
	}

	addNewPlaceMarker = () => {
		const { placeData } = this.state;
		
		this.setState({
			placeData: {
				...placeData,
				lat: this.polygonCenter && this.polygonCenter.lat(),
				lng: this.polygonCenter && this.polygonCenter.lng()
			}
		}, this.setMapsToFitBounds);
	}

	checkMarkerInsideGeofence = (lat, lng) => {
		const polygon = new window.google.maps.Polygon({
			paths: this.props.geofencePolygonArray
		});

		const markerLatLng = new window.google.maps.LatLng(lat, lng);
		const isWithinGeofence = google.maps.geometry.poly.containsLocation(markerLatLng, polygon);
		return isWithinGeofence;
	}

	setPlaceFullAddress = () => {
		let { placeData } = this.state;
		let { lat, lng } = placeData;

		this.geocoder.geocode({location: { lat, lng }}, (results, status) => {
			if (status === 'OK' && results[0]) {
					const fullAddress = results[0].formatted_address;
					this.setState({ 
					placeData: {
						...placeData,
						fullAddress
					}
				});	
			}
		});
	}

	handleChangePlaceCategory = e => {
		const { placeData } = this.state;
		const placeCategoryId = e.target.value;

		if (!placeData.AccessPoints || placeData.AccessPoints.length === 0)
			return this.setState({
				placeData: {
					...placeData,
					placeCategoryId,
					placeSubCategoryId: ''
				}
			});

		// Set all windows to deleted
		confirm('All access windows inside the access points will be deleted if you change the category. Do you wish to proceed?',
			() => {
				placeData.AccessPoints.forEach(ap => ap.AccessWindows && ap.AccessWindows.forEach(window => window.isDeleted = true));
				this.setState({
					placeData: {
						...placeData,
						placeCategoryId,
						placeSubCategoryId: '',
						AccessPoints: [
							...placeData.AccessPoints,
						]
					}
				});
			});
	}

	handleChangePlaceInput = e => {
		const { id, value, type, checked } = e.target;
		const finalValue = type === 'checkbox' ? checked : value;
		
		this.setState({ 
			placeData: {
				...this.state.placeData,
				[id]: finalValue
			}
		});
	}

	handlePlacePositionChanged = () => {
		const position = this.placeMarker.getPosition();
		const lat = position.lat();
		const lng = position.lng();

		this.setState({ 
			placeData: {
				...this.state.placeData,
				lat,
				lng
			}
		}, this.setPlaceFullAddress);
	}

	handleToggleAccessPointStatus = index => {
		const { placeData } = this.state;
		const AccessPoint = placeData.AccessPoints[index];
		if (!AccessPoint)
			return;

		if (AccessPoint.id)
		AccessPoint.isActive = !AccessPoint.isActive;
		else
			placeData.AccessPoints.splice(index, 1);

		this.setState({ placeData });
	}

	handleSaveAccessPoint = accessPointData => {
		const { selectedAccessPointIndex, placeData } = this.state;
		if (selectedAccessPointIndex !== null)
			placeData.AccessPoints[selectedAccessPointIndex] = accessPointData;
		else
			placeData.AccessPoints.push(accessPointData);

		this.setState({ 
			placeData,
			selectedAccessPointIndex: null,
			isAddingAccessPoint: false,
		});
	}

	handleChangeHasTailgateInput = e => {
		const { value } = e.target;
		
		this.setState({ 
			placeData: {
				...this.state.placeData,
				isTailgateRequired: value === 'true' ? true : false
			}
		});
	}

	handleClickSave = () => {
		this.form.submit();
	}

	savePlace = () => {
		const position = this.placeMarker.getPosition();
		const lat = position.lat();
		const lng = position.lng();

		if (!this.checkMarkerInsideGeofence(lat, lng))
			return alert('The place must be within the Geofence');

		const { placeData } = this.state;
		placeData.hasBeenEdited = true;
		this.props.onSave(placeData);
	}

	renderGeofencePolygon = () => {
		const options = {
			fillColor: '#00804d',
			fillOpacity: 0.2,
			strokeColor: '#000000',
			strokeOpacity: 0.9,
			strokeWeight: 1,
		};

		return (
			<Polygon  
				paths={this.props.geofencePolygonArray} 
				options={options}
				zIndex={1}
			/>
		);
	}

	renderPlaceMarker = () => {
		const { lat, lng, name } = this.state.placeData;
		
		return (
			<MarkerWithLabel 
				draggable
				ref={ref => this.placeMarker = ref}
				labelAnchor={new window.google.maps.Point(100, -5)}
				labelStyle={placeMarkerOptions}
				position={{ lat, lng }} 
				zIndex={2}
				onDragEnd={this.handlePlacePositionChanged}
			>
				<label>{ name }</label>
			</MarkerWithLabel>
		);
	}

	renderAccessPointsList = () => {
		const { AccessPoints } = this.state.placeData;

		return (
			<div>
				{
					AccessPoints && (
						<AccessPointsList
							accessPointsList={AccessPoints}
							onClickEditAccessPoint={index => this.setState({ selectedAccessPointIndex: index })}
							onToggleAccessPointStatus={index => this.handleToggleAccessPointStatus(index)}
						/>
					)
				}
				<Button
					bsSize="sm"
					bsStyle="primary"
					onClick={() => this.setState({ isAddingAccessPoint: true })}
				>
					Add a new Access Point
				</Button>
			</div>
		);
	}

	renderField = (id, label, col, type, className) => {
		const { placeData } = this.state;
		let field = null;
		switch(type) {
			case 'checkbox':
				field = (
					<FormGroup>
						<Checkbox
							id={id}
							checked={placeData[id]}
							onChange={this.handleChangePlaceInput}
						/>
					</FormGroup>
				);
				break;
			case 'placeCategoryType':
					field = (
						<PlaceCategoriesList 
							id={id} 
							value={placeData[id] || ''} 
							onChange={this.handleChangePlaceCategory} 
						/>
					);
					break;
			case 'placeSubCategoryType':
				field = (
					<PlaceSubCategoriesList 
						placeCategoryRequired
						id={id} 
						placeCategoryId={placeData.placeCategoryId}
						value={placeData[id] || ''} 
						onChange={this.handleChangePlaceInput} 
					/>
				);
				break;
			case 'isTailgateRequired':
				field = (
					<YesNoRadio 
						id="isTailgateRequired" 
						value={placeData.isTailgateRequired}
						onChange={this.handleChangeHasTailgateInput} 
					/>
				);
				break;
			default:
				field = (
					<FormControl 
						id={id}
						type={type && type !== 'textarea' ? type : undefined}
						componentClass={type === 'textarea' ? 'textarea' : undefined}
						className={className}
						rows={type === 'textarea' ? 3 : undefined}
						value={placeData[id] || ''} 
						onChange={this.handleChangePlaceInput} 
					/>
				);
		}

		return (
			<Col sm={col}>
				<FormGroup>
					<ControlLabel>{label}</ControlLabel>
					{ field }
				</FormGroup>
			</Col>
		)
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<Fragment>
				<Modal bsSize="lg" show={p.show} onHide={p.onClose}>
					<Modal.Header closeButton>
						<Modal.Title>
							{ p.placeData ? `Place #${p.placeData.id}` : 'Add New Place' }
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							s.isLoadingPlace || s.errorLoadingPlace ? (
								s.errorLoadingPlace ? (
									<ErrorBox error={s.errorLoadingPlace} retryFunc={this.fetchPlaceData} />
								) : (
									<Loader text="Loading data..." />
								)
							) : (
								<Fragment>
									<Form 
										refs={ref => this.form = ref}
										validations={this.validations}
										onSubmit={this.savePlace}
									>
										<Row>
											<Col lg={6}>
												<Row>
													{ this.renderField('placeCategoryId', 'Category *', 6, 'placeCategoryType') }
													{ this.renderField('placeSubCategoryId', 'Sub Category *', 6, 'placeSubCategoryType') }
												</Row>
												<Row>
													{ this.renderField('name', 'Name *', 12) }
												</Row>
												<GoogleMapContainer 		
													height="250px"	
													refs={ref => this.placesMap = ref}
												>
													{ this.renderGeofencePolygon() }
													{ !s.isLoadingMap && this.renderPlaceMarker() }
												</GoogleMapContainer>
											</Col>
											<Col lg={6}>
												<Row>
													{ this.renderField('storeNumber', 'Store Number *', 6) }
													{ this.renderField('maxPalletCapacity', 'Max Pallet Capacity', 6) }
												</Row>
												<Row>
													{ this.renderField('landlineNumber', 'Landline Number', 6, 'tel', 'phone') }
													{ this.renderField('mobileNumber', 'Mobile Number', 6, 'tel', 'phone') }
												</Row>
												<Row>
													{ this.renderField('email', 'Email', 12) }
													{ this.renderField('fullAddress', 'Address *', 12) }
												</Row>
												<Row>
													{ this.renderField('keySerialNumber', 'Key Serial Number', 4) }
													{ this.renderField('doorCode', 'Door Code', 4) }
													{ this.renderField('alarmCode', 'Alarm Code', 4) }
												</Row>
												<Row>
													{ this.renderField('isTailgateRequired', 'Tailgate Required?', 4, 'isTailgateRequired') }
												</Row>
												<Row>
													{ this.renderField('isPreStartEnabled', 'Enable Pre-Start', 4, 'checkbox') }
													{ this.renderField('isStartShiftEnabled', 'Enable Shift Start', 4, 'checkbox') }
													{ this.renderField('isSafePlaceEnabled', 'Enable Safe Place', 4, 'checkbox') }
													{ this.renderField('isPalletJackLiveEnabled', 'Enable PJ Live Monitor', 4, 'checkbox') }
												</Row>
											</Col>
										</Row>
									</Form>
									<h3 className="title">Access Points</h3>
									<Row>
										<Col lg={6}>	
											<GoogleMapContainer
												height="250px"	
												refs={ref => this.accessPointsMap = ref}
											>
												{ this.renderGeofencePolygon() }
												{ 
													!s.isLoadingMap && s.placeData.AccessPoints && s.placeData.AccessPoints.map((dp, index) => (
														<AccessPointMarker 
															key={index}
															labelText={'AP ' + (index + 1).toString()}
															lat={dp.accessPointLat}
															lng={dp.accessPointLng}
															isActive={dp.isActive}
														/>
													))
												}
											</GoogleMapContainer>
										</Col>
										<Col lg={6}>
											{ this.renderAccessPointsList() }
										</Col>
									</Row>
								</Fragment>
							)
						}
					</Modal.Body>
					<Modal.Footer>
						<Button className="pull-left" onClick={this.handleClickSave} bsStyle="success">Save</Button>
						<Button onClick={p.onClose} bsStyle="danger">Cancel</Button>
					</Modal.Footer>
				</Modal>
				{
					(s.isAddingAccessPoint || s.selectedAccessPointIndex !== null) && (
						<AccessPointModal 
							show
							data={!s.isAddingAccessPoint ? s.placeData.AccessPoints[s.selectedAccessPointIndex] : null}
							placeCategoryId={s.placeData.placeCategoryId}
							geofencePolygonArray={p.geofencePolygonArray}
							geofencePolygonBounds={p.geofencePolygonBounds}
							onSave={this.handleSaveAccessPoint}
							onClose={() => this.setState({ isAddingAccessPoint: false, selectedAccessPointIndex: null })}
						/>
					)
				}
			</Fragment>
		);
	}
}

PlaceModal.propTypes = propTypes;

export default PlaceModal;