import React, { Fragment } from 'react';
import { Modal, Button, Tab, Col, Nav, NavItem, Row, FormGroup } from 'react-bootstrap';
import { Loader, ErrorBox, UserDetailsLink, UserProfilePicture, ContentBox, Callout } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { IError, IReactSelectOption } from '../../../../../app/models/Application';
import PalletApi from '../../../../../app/api/PalletApi';
import PalletLoadDetailsForm from './PalletLoadDetailsForm';
import { IPallet, IPalletLoadDetails, IPalletLoadedBy, PalletDetailsForm, PalletLoad_PUT, PalletLoadDetailsForm as PalletLoadDetailsFormClass } from '../../../../../app/models/Pallet';
import moment from 'moment';
import DurationParse from '../../../../../app/helpers/DurationParse';
import Icon from 'react-fontawesome';
import $ from'jquery';
import PalletsList from './PalletsList';
import FontAwesome from 'react-fontawesome';
import toastr from 'toastr';
import PalletReorder from './PalletReorder';
import confirm from '../../../../../app/helpers/confirm';
import PalletDetailsModal from './PalletDetailsModal';

interface IProps {
	loadId?: string;
	onClose: () => void;
	onCompleteSaving?: (loadId: string) => void;
	onCompleteDeletion?: () => void;
}

interface IState {
	details: PalletLoadDetailsFormClass;
	isReadOnly: boolean;
	selectedPallet?: IPallet;
	newPallet: IPallet;
	extraLoaders: IPalletLoadedBy[];

	isAddingNewPallet?: boolean;
	isSaving: boolean;
	isLoading: boolean;
	isGeneratingLoadAuthorisation: boolean;
	isGeneratingLoadSummary: boolean;

	errorSaving?: IError;
	errorLoading?: IError;
	errorDeleting?: IError;
}

class PalletLoadDetailsModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { 
			details: new PalletLoadDetailsFormClass(),
			isReadOnly: false,
			selectedPallet: undefined,
			newPallet:{
				palletId: '',
				store: { id: 0, storeNumber: '', name: '' },
				manuallyEntered: true,
				isUpstacked: false,
				isDeleted: false,
				loadedOn: new Date().toString(),
				isDamaged: false,
				palletType: '',
				locationInTrailer: '',
				commodity: '',
				commodityManuallyEntered: true,
				loadedBy: { id: '', name: '' }
			},
			extraLoaders: [],

			isAddingNewPallet: false,
			isLoading: false,
			isSaving: false,
			isGeneratingLoadAuthorisation: false,
			isGeneratingLoadSummary: false,

			errorLoading: undefined,
			errorSaving: undefined,
			errorDeleting: undefined
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.loadId !== prevProps.loadId)
			this.fetchDetails();
	}	

	componentDidMount() {
		this.fetchDetails();
	}

	fetchDetails = () => {
		if (!this.props.loadId)
			return;

		this.setState({ errorLoading: undefined, isLoading: true });
		PalletApi.loads.getLoadDetails(this.props.loadId)
			.then((details: IPalletLoadDetails) => {
				const extraLoaders: IPalletLoadedBy[] = [];
				// Get all loaders that helped excluding the main loader who submitted the load
				details.pallets.forEach(pallet => {
					if (pallet.loadedBy?.name.length > 0 && details.workerName !== pallet.loadedBy?.name && !extraLoaders?.some(loader => loader?.name === pallet.loadedBy?.name)){
						extraLoaders?.push(pallet.loadedBy)
					}
				});
				this.setState({ 
					isReadOnly: !!details.loadCancelledOn,
					details: new PalletLoadDetailsFormClass(details),
					extraLoaders: extraLoaders
				})
			})
			.catch(errorLoading => this.setState({ errorLoading }))
			.finally(() => this.setState({ isLoading: false }))
	}

	getLoadAuthorisation = async () => {
		if (!this.props.loadId)
			return;

		this.setState({ isGeneratingLoadAuthorisation: true });

		try {
			await PalletApi.loads.getLoadAuthorisationFile(this.props.loadId);
		} catch (error) {
			console.error(error);
			toastr.error('Error generating Load Authorisation');
		}
		finally {
			this.setState({ isGeneratingLoadAuthorisation: false });
		}
	}

	getLoadSummary = async () => {
		if (!this.props.loadId)
			return;

		this.setState({ isGeneratingLoadSummary: true });

		try {
			await PalletApi.loads.getLoadSummaryFile(this.props.loadId);
		} catch (error) {
			console.error(error);
			toastr.error('Error generating Load Summary');
		}
		finally {
			this.setState({ isGeneratingLoadSummary: false });
		}
	}

	saveLoad = () => {
		const { loadId } = this.props;
		const dataToSend = new PalletLoad_PUT(this.state.details);
		let promise = () => PalletApi.loads.createLoad(dataToSend);

		if (loadId)
			promise = () => PalletApi.loads.updateLoad(loadId, dataToSend);

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(() => {
			this.fetchDetails();
		})
	}

	deleteLoad = () => {
		const { loadId, onCompleteDeletion } = this.props;
		if (!loadId) return;

		confirm('Are you sure you want to delete this load? This action cannot be undone.',
			() => {
				const promise = PalletApi.loads.deleteLoad.bind(this, loadId);
				componentRequestHandler(this, promise, undefined, {
					loadingAttrName: 'isSaving',
					errorAttrName: 'errorSaving',
					showSuccessNotification: true
				})
				.then(onCompleteDeletion)
			}
		)
	}

	changeForm = (newData: object) => {
		this.setState({
			details: {
				...this.state.details,
				...newData
			}
		})
	}
	
	handleChangeForm = (id: string, value: any) => {
		this.changeForm({ [id]: value })
	}

	handleChangeLoader = (loader: IReactSelectOption) => {
		this.changeForm({ workerId: loader.value, workerName: loader.label });
	}

	handleSave = () => {
		if (!$('#pallet-load-form').valid())
			return;
		
		if (!$('#pallet-reorder-form').valid()) {
			const modalElement = document.getElementById('pallet-load-modal');
			const firstInvalidInputElement = document.querySelectorAll('[class*="error"]')[0];
			if (modalElement && firstInvalidInputElement) {
				var scrollTop = document.documentElement.scrollTop;
		
				// Scroll to the element's position
				modalElement.scrollTo({
					top: scrollTop,
					behavior: 'smooth'
				});
			}
						
			return;
		}

		this.saveLoad();
	}

	// handleToggleEditMode = () => {
	// 	this.setState({ 
	// 		editMode: !this.state.editMode
	// 	}, () => {

	// 		// Setting all pallets to not deleted if edit mode is disabled
	// 		if (!this.state.editMode)
	// 			this.setState({
	// 				details: {
	// 					...this.state.details,
	// 					pallets: this.state.details.pallets.map(p => ({ ...p, isDeleted: false }))
	// 				}
	// 			})
	// 	});
	// }

	handleChangePalletMap = (pallets: IPallet[]) => {
		this.changeForm({ pallets })
	}

	handleAddNewPallet = () => {
		this.setState({ isAddingNewPallet: true })
	}

	handleDeletePallet = (pallet: IPallet) => {
		const palletToDelete = this.state.details.pallets.find(p => p.palletId === pallet.palletId);
		if (!palletToDelete) return;

		// Toggle the pallet's deleted status as it can be undone
		palletToDelete.isDeleted = !palletToDelete.isDeleted;

		this.changeForm({ pallets: this.state.details.pallets });
	}

	// handleClickEditPallet = () => {
	// 	this.setState({ editMode: true });
	// }

	handleSelectPallet = (selectedPallet?: IPallet) => {
		this.setState({ selectedPallet });
	}

	handleSavePallet = (pallet: PalletDetailsForm, palletsList?: IPallet[]) => {
		const { details } = this.state;
		const palletIndex = details.pallets.findIndex(p => p.palletId === pallet.palletId);

		if (palletIndex === -1)
			return;

		details.pallets[palletIndex] = { ...pallet };

		// Filtering out any newly upstacked pallets and sending the new list back to the form
		let newPalletsList = details.pallets.filter(pallet => !details.pallets[palletIndex].isUpstackedWith?.some(
			upstacked => upstacked.palletId === pallet.palletId)
		);

		// `palletsList` will be a list of available pallets that can be consolidated, if it exists
		// We need to combine the new pallets list with the existing pallets list to ensure that we don't have duplicates 
		if(palletsList) {
			const combined = [...newPalletsList, ...palletsList];
			newPalletsList = combined.filter((item, index, self) => 
				index === self.findIndex((obj) => obj.palletId === item.palletId)
			);
		}

		this.setState({ 
			details: {
				...details,
				pallets: [...newPalletsList ]
			}, 
			selectedPallet: undefined 
		});
	}

	handleSaveNewPallet = (pallet: PalletDetailsForm) => {
		const { details } = this.state;
		details.pallets.push(pallet);
		this.setState({ details: {...details}, isAddingNewPallet: false });
	}

	getStatus = () => {
		const { details } = this.state;

		if (!details)
			return {};
		
		if (details.loadCancelledOn)
			return {
				color: 'danger',
				text: 'CANCELLED'
			}
		else if (details.loadFinishedOn)
			return {
				color: 'success',
				text: 'COMPLETED'
			}
		
		return {
			color: 'primary',
			text: 'IN PROGRESS'
		}
	}

	renderBodyContent = () => {
		const { state, props } = this;
		const status = this.getStatus();

		if (state.errorLoading)
			return <ErrorBox error={state.errorLoading} retryFunc={this.fetchDetails} />;

		if (state.isSaving || state.isLoading)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;


		const { details } = state;
		const loadTimeSpentInSecs = details.loadFinishedOn ? moment.duration(moment(details.loadFinishedOn).diff(details.loadStartedOn)).asSeconds() : undefined;
		

		return (
			<Row>
				<Col md={4} lg={3}>
					{/* {
						// Show Edit button if load has an ID
						props.loadId && (
							<FormGroup>
								<Button 
									block
									bsSize="lg"
									bsStyle={state.editMode ? "primary" : "warning"}
									onClick={this.handleToggleEditMode}
								>
									<FontAwesome name={state.editMode ? "arrow-left" : "edit"} />
									&nbsp;
									{ state.editMode ? "Disable Edit Mode" : "Edit Load" }
								</Button>
							</FormGroup>
						)
					} */}
					<ContentBox title={state.extraLoaders.length > 0 ? 'Loaders' : 'Loader'}>
						<UserProfilePicture className="profile-user-img img-responsive" azureId={state.details.workerId}  />
						<h3 className="profile-username text-center">
						<UserDetailsLink azureId={state.details.workerId}>
								<b>{ state.details.workerName }</b>
							</UserDetailsLink>
						</h3>
						{
							state.extraLoaders.length > 0 && state.extraLoaders.map((loader) => {
								return (
									<Fragment key={loader?.id}>
										<ul className='list list-unstyled'>
											<li>
												<b>Assisted By</b>
												<span className="pull-right">
												<UserDetailsLink azureId={loader.id}>
													<b>{ loader.name }</b>
												</UserDetailsLink>
												</span>
											</li>
										</ul>
									</Fragment>
								)
							})
						}
					</ContentBox>
					<ContentBox color={status.color}>
						<h4 className={`text-${status.color}`}>
							<b>LOAD {status.text}</b>
						</h4>
					</ContentBox>
					<ContentBox>
						<ul className="list list-unstyled">
							<li>
								<b>Loaded Pallets</b>
								<span className="pull-right">
									{ state.details.pallets.length } pallets
								</span>
							</li>
							<li>
								<b>Start Time</b>
								<span className="pull-right">
									{ moment(state.details.loadStartedOn).format('DD/MM/YY HH:mm') }
								</span>
							</li>
							<li>
								<b>Finish Time</b>
								<span className="pull-right">
									{ state.details.loadFinishedOn ? moment(state.details.loadFinishedOn).format('DD/MM/YY HH:mm') : <i>In Progress</i> }
								</span>
							</li>
							<li>
								<b>Time Spent</b>
								<span className="pull-right">
									{ loadTimeSpentInSecs ? DurationParse.toDayHourMinSec(loadTimeSpentInSecs, 'short') : <i>In Progress</i> }
								</span>
							</li>
						</ul>
					</ContentBox>
					{
						this.props.loadId && !details.loadCancelledOn && details.loadFinishedOn && (
							<>
								<FormGroup>
									<Button
										block
										bsStyle="info"
										disabled={state.isGeneratingLoadAuthorisation}
										onClick={this.getLoadAuthorisation}
									>
										<FontAwesome 
											name={state.isGeneratingLoadAuthorisation ? "spinner" : "download"} 
											spin={state.isGeneratingLoadAuthorisation} 
										/> { state.isGeneratingLoadAuthorisation ? "Generating..." : "Load Authorisation" }
									</Button>
								</FormGroup>
								<FormGroup>
									<Button
										block
										bsStyle="info"
										disabled={state.isGeneratingLoadSummary}
										onClick={this.getLoadSummary}
									>
										<FontAwesome 
											name={state.isGeneratingLoadSummary ? "spinner" : "download"} 
											spin={state.isGeneratingLoadSummary} 
										/> { state.isGeneratingLoadSummary ? "Generating..." : "Load Summary" }
									</Button>
								</FormGroup>
							</>
						)
					}
				</Col>
				<Col md={8} lg={9}>
					{ state.errorSaving && <ErrorBox error={state.errorSaving} retryFunc={this.saveLoad} /> }
					{ state.errorDeleting && <ErrorBox error={state.errorDeleting} retryFunc={this.deleteLoad} /> }
					<Tab.Container id="palletLoadTab" className="nav-tabs-custom" defaultActiveKey="details">
						<div>
							<Nav bsStyle="tabs">
								<NavItem eventKey="details">
									<Icon name="info-circle" /> Details
								</NavItem>
								<NavItem eventKey="edit">
									<Icon name="truck" /> Pallet Map
								</NavItem>
							</Nav>
							<Tab.Content animation={false}>
								<Tab.Pane eventKey="details">
									<PalletLoadDetailsForm 
										{...state.details}
										isReadOnly={props.loadId && state.isReadOnly ? true : false}
										onChange={this.handleChangeForm}
										onChangeLoader={this.handleChangeLoader}
									/>
									<PalletsList 
										isReadOnly={state.isReadOnly}
										isConsolidatedPallet={false}
										pallets={details.pallets}
										isAddingConsolidatedPalletFromList={false}
										onAddPallet={this.handleAddNewPallet}
										onSelectPallet={this.handleSelectPallet}
										onDeletePallet={this.handleDeletePallet}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey="edit">
									<PalletReorder 
										isReadOnly={state.isReadOnly}
										pallets={details.pallets}
										onChangePallets={this.handleChangePalletMap}
										// onClickEditPallet={this.handleClickEditPallet}
										onClickDeletePallet={this.handleDeletePallet}
									/>
								</Tab.Pane>
							</Tab.Content>
						</div>
					</Tab.Container>
				</Col>
			</Row>
		)
	}

	render() {
		const { state, props } = this;

		return (
			<>
				<Modal
					show
					id="pallet-load-modal"
					bsSize="large"
					onHide={props.onClose}
					onEscapeKeyDown={props.onClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{ props.loadId ? 'Load Details' : 'Add New Load' }
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							state.details.loadCancelledOn && (
								<Callout 
									icon="info-circle"
									title="Cancelled Load"
									text="This load has been cancelled and cannot be edited."
								/>
							)
						}
						{ this.renderBodyContent() }
					</Modal.Body>
					{
						!state.isSaving && !state.isLoading && (
							<Modal.Footer>
								{
									!state.isReadOnly && (
										<Button 
											bsStyle="success"
											className="pull-left"
											bsSize="lg"
											onClick={this.handleSave}
										>
											<FontAwesome name="check" /> { props.loadId ? 'Save Changes' : 'Save Load' }
										</Button>
									)
								}
								{
									props.loadId && (
										<Button
											bsStyle="danger"
											onClick={this.deleteLoad}
										>
											<FontAwesome name="trash" /> Delete Load
										</Button>
									)
								}
								<Button bsSize="lg" onClick={props.onClose}>
									<FontAwesome name="sign-out"/> Close Without Saving
								</Button>
							</Modal.Footer>
						)
					}
				</Modal>

				{/* PALLET DETAILS MODAL  */}
				{
					state.selectedPallet && (
						<PalletDetailsModal 
							palletsList={state.details.pallets.filter(p => !p.isUpstackedWith)}
							pallet={state.selectedPallet}
							isReadOnly={state.isReadOnly}
							onClose={() => {this.handleSelectPallet(undefined)}}
							onSave={this.handleSavePallet}
						/>
					)
				}
				
				{/* ADD NEW PALLET MODAL  */}
				{
					state.isAddingNewPallet && (
						<PalletDetailsModal 
							palletsList={state.details.pallets.filter(p => !p.isUpstackedWith)}
							pallet={state.newPallet}
							isReadOnly={state.isReadOnly}
							isAddingNewPallet={state.isAddingNewPallet}
							onClose={() => { this.setState({ isAddingNewPallet: false }) }}
							onSave={this.handleSaveNewPallet}
						/>
					)
				}
			</>
		)
	}
}

export default PalletLoadDetailsModal;